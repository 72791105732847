import styled from "styled-components"

import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 30px;
  border-top: ${props => `1px solid ${props.theme.colors.grey[1]}`};

  @media ${device.large} {
    margin-top: 60px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 24px;
    @media ${device.large} {
      font-size: 24px;
      margin-bottom: 34px;
    }
  }
`

export const ItemWrapper = styled.div`
  margin-left: -20px;
  display: flex;

  @media ${device.large} {
    margin-left: -30px;
  }
`

export const Item = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;

  svg {
    path {
      fill: black;
    }
  }
  text-decoration: none;

  :hover {
    img, svg {
      opacity: 0.7;
      transition: 100ms;
    }
  }

  cursor: pointer;
  span {
    color: ${props => props.theme.colors.grey[2]};
    font-size: 14px;
    font-weight: 600;
  }

  @media ${device.large} {
    padding: 0 30px;

    span {
      font-size: 16px;
    }
  }

  svg, img {
    width: 28px;
    margin-bottom: 10px;

    &.fb {
      width: 17px;
    }

    &.twitter {
      margin-top: 3px;
    }

    @media ${device.large} {
      width: 36px;
      &.fb {
        width: 22px;
      }
    }
  }
`
