import React, { useEffect, useRef, useState } from "react"
import {
  ModalContainer,
  ImageWrapper,
  ImageHighRes,
  ModalBody,
  CloseBtn,
  CrossIcon,
  ButtonsContainer,
  AdColumn,
  Title,
} from "./style"
import { ArticleSliderGrid } from "../../TeaserGrids"
import { Teaser } from "../../Teaser"
import { ReactComponent as Arrow } from "../../../images/right-arrow.svg"
import useOutsideAlerter from "../../../hooks/useOutsiderAlerter"
import { ReactComponent as LoadingAnimation } from "../../../images/loading-animation.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const ImageModal = function({
  title,
  setImageModalOpen,
  image,
  images,
  setModalImage,
  relatedPosts,
}) {
  const modalRef = useRef(null)

  useOutsideAlerter(modalRef, () => setImageModalOpen(false))
  const [currentImageIndex, setCurrentImageIndex] = useState(
    images.indexOf(image)
  )
  const [showImage, setShowImage] = useState(true)

  const previousImage = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(images.length - 1)
    } else {
      setCurrentImageIndex(c => c - 1)
    }
    trackCustomEvent({
      category: "image modal",
      action: "navigated",
      label: "article page",
    })
    setShowImage(false)
  }
  const nextImage = () => {
    if (currentImageIndex === images.length - 1) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(c => c + 1)
    }
    setShowImage(false)

    trackCustomEvent({
      category: "image modal",
      action: "navigated",
      label: "article page",
    })
  }

  const keypressHandler = e => {
    e.preventDefault()

    if (e.keyCode === 27) {
      setImageModalOpen(false)
    }

    if (e.keyCode === 37) {
      previousImage()
    }
    if (e.keyCode === 39) {
      nextImage()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", keypressHandler)
    // console.log("index", currentImageIndex)
    setModalImage(images[currentImageIndex])

    return function cleanup() {
      window.removeEventListener("keydown", keypressHandler)
    }
  }, [currentImageIndex, images, setModalImage])

  return (
    <ModalContainer>
      <ModalBody ref={modalRef}>
        <ImageWrapper showImage={showImage}>
          <LoadingAnimation />
          <ImageHighRes
            showImage={showImage}
            src={image.sourceUrl}
            onClick={e => e.stopPropagation()}
            onLoad={() => setShowImage(true)}
          />
        </ImageWrapper>

        <AdColumn>
          <CloseBtn onClick={() => setImageModalOpen(false)}>
            <CrossIcon />
          </CloseBtn>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <ButtonsContainer>
            <button onClick={() => previousImage()}>
              <Arrow />
            </button>
            <button onClick={() => nextImage()}>
              <Arrow />
            </button>
            <p>
              <span>{currentImageIndex + 1} </span> / {images.length}
            </p>
          </ButtonsContainer>

          <p>Use your arrow keys to navigate and ESC to close this window. </p>

          <ArticleSliderGrid title="More articles" editorialPost>
            {relatedPosts &&
              relatedPosts.map((teaser, index) => {
                return (
                  <Teaser
                    little
                    title={teaser.title}
                    link={teaser.link}
                    color={teaser.colourFeatureImage}
                    key={index}
                    excerpt={teaser.excerpt}
                    img={teaser.featuredImage}
                    categories={teaser.categories.nodes}
                  />
                )
              })}
          </ArticleSliderGrid>
        </AdColumn>
      </ModalBody>
    </ModalContainer>
  )
}

export default ImageModal
