import React, { useRef, useEffect, useState } from "react"
import Article from "./article"
import ImageModal from "./ImageModal"
import Shares from "../Shares/index"
import { Products } from "../Products"
import { Teaser } from "../Teaser"
import { AdvertisementWrapper } from "../AdvertisementWrapper"
import { GoogleAd } from "../GoogleAd"
import { LatestPostsData } from "../../hooks/latestPosts"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import { LatestEditorialsData } from "../../hooks/latestEditorials"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import {
  Images,
  Container,
  HeaderContainer,
  InnerContainer,
  ArticleImageWrapper,
  MainContainer,
  SecondaryContainer,
  HeaderBackground,
  VideoWrapper,
  ArticleImage,
} from "./indexStyles"
import { RelatedGrid, ArticleSliderGrid } from "../TeaserGrids"
import { FollowUs } from "../FollowUs"

import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import CustomBanner from "../CustomBanner/CustomBanner"
import NewsletterPopup from "../NewsletterPopup/NewsletterPopup"
import { GlobalBannersData } from "../../hooks/global-banners-data"

export default function Index(props) {
  const GET_RELATED_POSTS = gql`
    query($postID: Int!) {
      postBy(postId: $postID) {
        relatedPosts {
          title
          link
          colourFeatureImage
          excerpt
          slug
          categories {
            nodes {
              name
              slug
              link
            }
          }
          featuredImage {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  `

  const latestPostsHook = LatestPostsData()
  const latestPosts = latestPostsHook.posts.nodes.slice(0, 8)
  const rgb = props.color && JSON.parse(props.color).join()

  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState()
  const latestEditorials = LatestEditorialsData()
  const otherEditorials = latestEditorials.posts.filter(
    editorial => editorial.title !== props.title
  )
  const articleContainer = useRef(null)

  //Clean attachment image array and remove if image size is less than 40
  const cleanedImages =
    props.images && props.images.filter(img => img.mediaDetails.width > 400)

  //Create a new array that includes the headerImage and attachment images
  const allImages = props.images && [props.headerImage, ...cleanedImages]

  useEffect(() => {
    function loadPinterestScript() {
      const script = document.createElement("script")
      script.src = "//assets.pinterest.com/js/pinit.js"
      script.type = "text/javascript"
      script.dataset.pinBuild = "pinterestBuild"
      script.async = true
      script.defer = true
      articleContainer.current.appendChild(script)
    }

    if (!window.pinterestBuild) {
      loadPinterestScript()
    } else {
      window.pinterestBuild()
    }
  }, [])

  function handleImgClick(img, e) {
    if (typeof window !== `undefined`) {
      const mq = window.matchMedia("(min-width: 1200px)")
      if (!mq.matches) return false
      setImageModalOpen(true)
      setModalImage(img)
    }
  }

  const { data } = useQuery(GET_RELATED_POSTS, {
    variables: { postID: props.postId },
    ssr: false,
    skip: typeof window === "undefined",
  })

  const relatedPosts = data && data.postBy.relatedPosts

  // Banner stuff
  const globalBanners = GlobalBannersData()
  const randomNumber = Math.floor(Math.random() * globalBanners.length)
  const chosenNumber = props?.avBanner?.avBannerNumber
  const chosenBanner = globalBanners[chosenNumber - 1]
  const randomBanner = globalBanners[randomNumber]
  const bannerToUse = chosenBanner || randomBanner

  return (
    <Container
      noArticleHeaderImage={props.noArticleHeaderImage}
      ref={articleContainer}
    >
      {!props.noArticleHeaderImage && (
        <HeaderBackground noArticleHeaderImage={props.noArticleHeaderImage}>
          <ResponsiveGatsbyImage
            noAnimate
            loading="eager"
            noFade
            noArticleHeaderImage={props.noArticleHeaderImage}
            src={props.headerImage.sourceUrl}
            srcSet={props.headerImage.srcSet}
            sizes="(min-width: 1024px) 800px, 100vw"
            height={props.headerImage.mediaDetails.height}
            width={props.headerImage.mediaDetails.width}
            style={{ backgroundColor: props.color ? `rgb(${rgb})` : null }}
          />
        </HeaderBackground>
      )}{" "}
      <MainContainer>
        {!props.noArticleHeaderImage && (
          <ArticleImageWrapper>
            <button
              onClick={e => {
                e.preventDefault()
                trackCustomEvent({
                  category: "image modal",
                  action: "Click",
                  label: "artical feature image",
                })
                handleImgClick(props.headerImage, e)
              }}
            >
              <ResponsiveGatsbyImage
                loading="eager"
                noFade
                src={props.headerImage.sourceUrl}
                srcSet={props.headerImage.srcSet}
                sizes="(min-width: 1024px) 800px, 100vw"
                height={props.headerImage.mediaDetails.height}
                style={{
                  backgroundColor: props.color ? `rgb(${rgb})` : null,
                }}
                width={props.headerImage.mediaDetails.width}
              />
            </button>
          </ArticleImageWrapper>
        )}

        <HeaderContainer noArticleHeaderImage={props.noArticleHeaderImage}>
          {props.article && <Shares link={props.shareLink} />}

          <InnerContainer>
            <Article
              article={props.article}
              postLabel={props.postLabel}
              date={props.date}
              title={props.title}
              content={props.content}
              categories={props.categories}
            />
          </InnerContainer>
        </HeaderContainer>

        {props.article && (
          <Images noArticleHeaderImage={props.noArticleHeaderImage}>
            {props.videoAttributes.embedCode && (
              <VideoWrapper
                noArticleHeaderImage={props.noArticleHeaderImage}
                order={props.videoAttributes.videoOrder}
                dangerouslySetInnerHTML={{
                  __html: props.videoAttributes.embedCode,
                }}
              />
            )}

            <AdvertisementWrapper
              articleHorizontal
              order={Math.floor(cleanedImages.length / 2 - 1)}
            >
              <GoogleAd
                className="articleHorizontal"
                client="ca-pub-4461681428787239"
                slots={["3835518671", "3835518671", "7179673797"]}
              />
            </AdvertisementWrapper>

            {cleanedImages.map((img, index) => {
              // console.log("index:", index)
              // console.log("img:", img)
              return (
                <div>
                  <button
                    key={`img${index}`}
                    onClick={e => {
                      e.preventDefault()
                      trackCustomEvent({
                        category: "image modal",
                        action: "Click",
                        label: "artical page images",
                        value: index,
                      })
                      handleImgClick(img, e)
                    }}
                  >
                    <ArticleImage
                      order={index}
                      src={img.sourceUrl}
                      srcSet={img.srcSet}
                      sizes="(min-width: 1024px) 800px, 100vw"
                      height={img.mediaDetails.height}
                      width={img.mediaDetails.width}
                    />
                  </button>
                  {img.caption && (
                    <figcaption>
                      {img.caption && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: img.caption,
                          }}
                        />
                      )}
                      {img.altText && (
                        <a
                          href={img.altText}
                          target="_blank"
                          dangerouslySetInnerHTML={{
                            __html: img.description,
                          }}
                        />
                      )}
                    </figcaption>
                  )}

                  {cleanedImages?.length > 8 &&
                    index === Math.floor(cleanedImages.length / 2) && (
                      <CustomBanner {...bannerToUse} />
                    )}
                </div>
              )
            })}

            <NewsletterPopup />
          </Images>
        )}

        {props.article && (
          <Shares link={props.shareLink} author={props.author} />
        )}
      </MainContainer>
      <SecondaryContainer>
        <AdvertisementWrapper articleSquare>
          <GoogleAd
            className="square"
            client="ca-pub-4461681428787239"
            slots={["5453876193"]}
          />
        </AdvertisementWrapper>

        <AdvertisementWrapper articleVertical>
          <GoogleAd
            className="articleVertical"
            client="ca-pub-4461681428787239"
            slots={["8983415873"]}
          />
        </AdvertisementWrapper>

        <ArticleSliderGrid title="More articles" editorialPost>
          {otherEditorials.slice(0, 4).map((teaser, index) => {
            return (
              <Teaser
                little
                title={teaser.title}
                link={teaser.link}
                color={teaser.colourFeatureImage}
                key={index}
                excerpt={teaser.excerpt}
                img={teaser.featuredImage}
                categories={teaser.categories.nodes}
              />
            )
          })}
        </ArticleSliderGrid>

        {props.article && relatedPosts && (
          <ArticleSliderGrid title="You might like these too">
            {cleanedImages.length > 6
              ? relatedPosts.slice(0, 4).map((teaser, index) => {
                  return (
                    <Teaser
                      // little
                      title={teaser.title}
                      slug={teaser.slug}
                      key={index}
                      color={teaser.colourFeatureImage}
                      excerpt={teaser.excerpt}
                      img={teaser.featuredImage}
                      categories={teaser.categories.nodes}
                    />
                  )
                })
              : relatedPosts.slice(0, 4).map((teaser, index) => {
                  return (
                    <Teaser
                      title={teaser.title}
                      slug={teaser.slug}
                      color={teaser.colourFeatureImage}
                      key={index}
                      excerpt={teaser.excerpt}
                      img={teaser.featuredImage}
                      categories={teaser.categories.nodes}
                    />
                  )
                })}
          </ArticleSliderGrid>
        )}

        {props.article && (
          <AdvertisementWrapper desktop articleSquare>
            <GoogleAd
              className="square"
              client="ca-pub-4461681428787239"
              slots={["9959210338"]}
            />
          </AdvertisementWrapper>
        )}

        <Products
          marginTop={props.article ? null : true}
          article
          title="#want"
        />
        <FollowUs />
        {props.article && (
          <AdvertisementWrapper articleSquare>
            <GoogleAd
              className="square"
              client="ca-pub-4461681428787239"
              slots={["8589151908"]}
            />
          </AdvertisementWrapper>
        )}
      </SecondaryContainer>
      <RelatedGrid title={props.article ? "Related" : "Latest"}>
        {props.article &&
          relatedPosts &&
          relatedPosts.map((teaser, index) => {
            return (
              <Teaser
                key={index}
                small
                color={teaser.colourFeatureImage}
                title={teaser.title}
                slug={teaser.slug}
                img={teaser.featuredImage}
              />
            )
          })}
        {!props.article &&
          latestPosts.map((teaser, index) => {
            return (
              <Teaser
                key={index}
                small
                title={teaser.title}
                color={teaser.colourFeatureImage}
                slug={teaser.link}
                img={teaser.featuredImage}
              />
            )
          })}
      </RelatedGrid>
      <Products articleBottom title="Awesome products for creatives" />
      {imageModalOpen && (
        <ImageModal
          relatedPosts={relatedPosts.slice(0, 3)}
          title={props.title}
          image={modalImage}
          setImageModalOpen={setImageModalOpen}
          images={allImages}
          setModalImage={setModalImage}
        />
      )}
    </Container>
  )
}
