import styled from "styled-components"
import { device } from "../../utils/breakpoints"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"

export const Container = styled.div`
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  margin: 0 auto;
  max-width: ${props => props.theme.mainMaxWidth};
  padding-top: ${props => (props.noArticleHeaderImage ? "30px" : "20px")};
  @media ${device.tablet} {
    width: ${props => `calc(100% - 2*${props.theme.desktopGutter})`};
    padding-top: ${props => (props.noArticleHeaderImage ? "54px" : "120px")};
  }

  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${props => props.theme.desktopGap};
    width: calc(100% - 120px);
    padding-top: ${props => (props.noArticleHeaderImage ? "54px" : "144px")};
  }

  @media ${device.large} {
    grid-column-gap: ${props => props.theme.largeGap};
    padding-top: ${props => (props.noArticleHeaderImage ? "54px" : "170px")};
  }
`

export const MainContainer = styled.div`
  @media ${device.desktop} {
    /* padding-bottom: 100px; */
    grid-column: span 2;
  }
`

export const HeaderContainer = styled.div`
  margin-bottom: 40px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  @media ${device.desktop} {
    margin-top: ${props => (props.noArticleHeaderImage ? "0" : "40px")};
    margin-bottom: 40px;
  }
  @media ${device.large} {
    margin-top: ${props => (props.noArticleHeaderImage ? "0" : "70px")};

    margin-bottom: 80px;
  }
`
export const InnerContainer = styled.div`
  position: relative;

  @media ${device.tablet} {
    margin-left: 45px;
  }
`

export const ImageText = styled.div``

export const ArticleImageWrapper = styled.div`
  /* height: 100%; */
  width: 100%;
  position: relative;
  display: none;
  padding-top: 70.3333332%;
  margin-bottom: 20px;

  @media ${device.tablet} {
    display: block;
  }
  .gatsby-image-wrapper,
  img,
  button {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;

    @media (min-width: 1200px) {
      cursor: zoom-in;
    }
  }
`

export const SecondaryContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;

  @media ${device.desktop} {
    grid-column: span 1;
  }
`

export const Advert = styled.div`
  padding-top: 80%;
  width: 100%;
  background: ${props => props.theme.colors.pink};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;

  @media ${device.desktop} {
    margin-top: 0;
    margin-bottom: ${props => (props.noArticleHeaderImage ? "25PX" : "70PX")};
  }

  @media ${device.large} {
    margin-bottom: ${props => (props.noArticleHeaderImage ? "30PX" : "90PX")};
  }
`
export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  height: 310px;

  @media ${device.tablet} {
    display: block;
    height: 400px;
  }

  @media ${device.desktop} {
    height: 454px;
  }

  @media ${device.large} {
    height: 536px;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    z-index: 2;
    z-index: 0;
  }

  .gatsby-image-wrapper,
  img {
    position: absolute;
    width: 100%;

    height: 100%;
    z-index: -3;
    margin: 0;
    object-fit: cover;
    transform: scale(1.1);

    @media ${device.tablet} {
      filter: blur(12px);
    }
  }
`

export const Images = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;

  button {
    outline: none;
    border: none;
    padding: 0;
    width: 100%;
  }
  @media ${device.desktop} {
    grid-row-gap: 60px;
  }

  img {
    margin: 0;
  }

  > img,
  .gatsby-image-wrapper {
    display: block;
    margin: 0px auto;
    /* margin-bottom: 20px; */
    width: 100%;
    max-width: ${props => props.noArticleHeaderImage && "576px"};

    @media ${device.desktop} {
      max-width: ${props => props.noArticleHeaderImage && "85%"};
    }

    @media ${device.large} {
      max-width: ${props => props.noArticleHeaderImage && "576px"};
    }
  }

  margin-bottom: 20px;

  figcaption {
    background: white;
    text-align: left;
    font-size: 14px;
    padding-top: 10px;
    color: #545c52;
    margin-bottom: 10px;
    @media ${device.desktop} {
      margin-bottom: 0px;
      font-size: 16px;
    }

    a {
      color: ${props => props.theme.colors.black};
      font-weight: 700;
      text-decoration-line: none;
      position: relative;
      pointer-events: all;
      :hover {
        color: ${props => props.theme.colors.teal};
        transition: 90ms;
      }

      ::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        display: inline-block;
        height: 2px;
        width: 100%;
        background: ${props => props.theme.colors.turquoise};
      }
    }

    p {
      display: inline;
    }
  }
`

export const ArticleImage = styled(ResponsiveGatsbyImage)`
  order: ${props => props.order};
  display: block;
  margin: 0px auto;
  /* margin-bottom: 20px; */
  width: 100%;
  max-width: ${props => props.noArticleHeaderImage && "576px"};

  @media (min-width: 1200px) {
    cursor: zoom-in;
  }

  @media ${device.desktop} {
    max-width: ${props => props.noArticleHeaderImage && "85%"};
  }

  @media ${device.large} {
    max-width: ${props => props.noArticleHeaderImage && "576px"};
  }
`

export const VideoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  order: ${props => props.order};
  justify-items: center;
  max-width: 100%;
  overflow: hidden;
  @media ${device.desktop} {
    grid-row-gap: 60px;
  }
  max-width: ${props => props.noArticleHeaderImage && "576px"};

  @media ${device.desktop} {
    max-width: ${props => props.noArticleHeaderImage && "85%"};
  }

  @media ${device.large} {
    max-width: ${props => props.noArticleHeaderImage && "576px"};
  }

  br {
    display: none;
  }

  iframe.instagram-media,
  blockquote.instagram-media {
    margin: 0;
    max-width: calc(100vw - 44px) !important;
    width: 100% !important;
    min-width: auto !important;

    @media (min-width: 540px) {
      max-width: calc(100% - 4px) !important;
    }
  }

  margin: 0px auto;
  width: 100%;
  position: relative;

  > div:has(>iframe), &:has(>iframe) {
    margin: 0px auto;
    width: 100%;
    position: relative;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


`
