import React from "react"
import styled from "styled-components"
import { device } from "../../utils/breakpoints"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import Img from "gatsby-image"

export const Wrapper = styled.div`
  background: #f4f4f4;
  margin: 0px auto;
  margin-top: 20px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${device.desktop} {
    margin-top: 50px;
  }
  ${props =>
    props.variant === "square" &&
    `
    width:360px;
    height:290px;
    
   `};

  ${props =>
    props.variant === "horizontal" &&
    `
    width:360px;
    height:80px;

    @media ${device.large} {
      width:100%;
      height:auto;  
      padding:20px;
    }
    
   `};
`

export const BannerImage = styled(Img)`
  ${props =>
    props.variant === "square" &&
    `
    width:320px !important;
    height:250px !important;
   `};

  ${props =>
    props.variant === "horizontal" &&
    `
    width:320px !important;
    height:50px;

    @media ${device.large} {
      width:728px !important;
      height:90px !important;  
      
    }
   `};

  @media ${device.large} {
    display: none !important;
  }
`

export const DesktopBannerImage = styled(BannerImage)`
  display: none !important;

  @media ${device.large} {
    display: block !important;
  }
`

const CustomBanner = ({ variant, mobileImage, desktopImage, link }) => {
  if ((!mobileImage && !desktopImage) || !link) return null
  return (
    <Wrapper variant={variant}>
      <a target="_blank" href={link}>
        <BannerImage
          variant={variant}
          fluid={{
            src: mobileImage?.sourceUrl || desktopImage?.sourceUrl,
            srcSet: mobileImage?.srcSet || desktopImage?.srcSet,
            sizes: "728px",
          }}
        />

        <DesktopBannerImage
          variant={variant}
          fluid={{
            src: desktopImage?.sourceUrl || mobileImage?.sourceUrl,
            srcSet: desktopImage?.srcSet || mobileImage?.srcSet,
            sizes: "728px",
          }}
        />
      </a>
    </Wrapper>
  )
}

export default CustomBanner
