import { useStaticQuery, graphql } from "gatsby"

// avGlobalBanners

export const GlobalBannersData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query globalBannersQuery {
        wpgraphql {
          justins(where: { id: 101942 }) {
            nodes {
              title
              avSiteOptions {
                avGlobalBanners {
                  variant
                  mobileImage {
                    sourceUrl
                    srcSet
                  }
                  desktopImage {
                    sourceUrl
                    srcSet
                  }
                  link
                }
              }
            }
          }
        }
      }
    `
  )

  return wpgraphql?.justins?.nodes[0]?.avSiteOptions?.avGlobalBanners
}
