import React from "react"
import Article from "../components/FeaturedArticle/index"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Post = props => {
  const { pageContext } = props
  console.log("props:", props)

  const noArticleHeaderImage = pageContext.metadata.lowresImages

  const {
    videoAttributes,
    title,
    content,
    excerpt,
    attachments,
    featuredImage,
    categories,
    date,
    colourFeatureImage,
    slug,
    author,
    postId,
    postLabel,
    avBanner,
  } = pageContext

  return (
    <Layout
      article
      noArticleHeaderImage={noArticleHeaderImage}
      headerImage={featuredImage}
    >
      <SEO
        title={title}
        image={featuredImage}
        description={excerpt}
        url={`${process.env.GATSBY_WEBSITE_DOMAIN}/${slug}/`}
      />
      <Article
        avBanner={avBanner}
        postLabel={postLabel}
        postId={postId}
        videoAttributes={videoAttributes}
        article
        color={colourFeatureImage}
        title={title}
        content={content}
        images={attachments.nodes}
        noArticleHeaderImage={noArticleHeaderImage}
        headerImage={featuredImage}
        categories={categories.nodes}
        date={date}
        author={author}
        shareLink={`${process.env.GATSBY_WEBSITE_DOMAIN}/${slug}/`}
      />
    </Layout>
  )
}

export default Post
