import React from 'react';
import { PinterestShareCount, FacebookShareButton, TwitterShareButton } from 'react-share';
import { kFormatter } from '../../utils/helpers';
import AuthorLogo from '../../images/author-ig-logo.png';
import { ReactComponent as FacebookLogo } from '../../images/facebook-logo.svg';
import { ReactComponent as TwitterLogo } from '../../images/twitter-logo.svg';
import { ReactComponent as PinterestLogo } from '../../images/pinterest-logo.svg';

import {
	SharesContainer,
	CountContainer,
	ButtonsContainer,
	Number,
	Text,
	Container,
	AuthorContainer,
	AuthorText
} from './indexStyles';

export default function Shares({ link, author }) {

	const pageUrl = link;
	const httpUrl = pageUrl.replace('https://', 'http://');

	return (
		<Container author={author}>
			{author && (
				<AuthorContainer>
					<img src={author.name === 'admin' ? AuthorLogo : author.avatar.url} alt="" />
					<AuthorText>
						<span>POSTED BY</span>
						<h4>{author.name === 'admin' ? 'IG Team' : author.name}</h4>
					</AuthorText>
				</AuthorContainer>
			)}

			<SharesContainer author={author}>
				<ButtonsContainer author={author}>
					<FacebookShareButton url={pageUrl}>
						<button className="facebook">
							<FacebookLogo />
						</button>
					</FacebookShareButton>

					<TwitterShareButton url={pageUrl}>
						<button className="twitter-logo">
							<TwitterLogo />
						</button>
					</TwitterShareButton>

					<a
						className="pinBtn"
						data-pin-do="buttonBookmark"
						data-pin-custom="true"
						href="https://www.pinterest.com/pin/create/button/"
					>
						<button className="pinterest">
							<PinterestLogo />
						</button>
					</a>
				</ButtonsContainer>
				<CountContainer author={author}>
					<Number author={author}>
						<PinterestShareCount url={pageUrl}>
							{(shareCount) => (
								<PinterestShareCount url={httpUrl}>
									{(httpShareCount) => <span>{kFormatter(shareCount + httpShareCount)}</span>}
								</PinterestShareCount>
							)}
						</PinterestShareCount>
					</Number>
					<Text author={author}>Shares</Text>
				</CountContainer>
			</SharesContainer>
		</Container>
	);
}
