import styled from 'styled-components';

import { device } from '../../utils/breakpoints';

export const Container = styled.div`
	margin-top: ${(props) => props.author && '50px'};
	margin-bottom: ${(props) => props.author && '50px'};

	display: ${(props) => props.author && 'flex'};
	justify-content: ${(props) => props.author && 'space-between'};
	padding: ${(props) => props.author && '20px 15px'};
	padding-right: ${(props) => props.author && '35px'};
	background: ${(props) => props.author && '#F5F8F8'};
	flex-direction: column;

	@media (min-width: 600px) {
		padding: ${(props) => props.author && '20px 15px'};
		flex-direction: row;
	}
	@media ${device.desktop} {
		margin-top: ${(props) => props.author && '60px'};
		margin-bottom: ${(props) => props.author && '60px'};

		padding: ${(props) => props.author && '30px 23px'};
	}
`;

export const AuthorContainer = styled.div`
	display: flex;
	img {
		height: 36px;
		width: 36px;
		margin-bottom: 0;
		margin-right: 16px;
		border-radius:50%;

		@media ${device.desktop} {
			height: 60px;
			width: 60px;
		}
	}
`;

export const AuthorText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-bottom: 8px;
	border-bottom: 2px solid #d7e1e2;

	@media (min-width: 600px) {
		border-bottom: 0;
		padding-bottom: 0px;
	}
	span {
		font-size: 8px;
		font-family: 'AvenirNext';
		font-weight: 600;
		color: #9cacae;
		margin-bottom: 4px;

		@media ${device.desktop} {
			font-size: 10px;
		}
	}

	h4 {
		font-size: 18px;
		margin-bottom: 0;

		@media ${device.desktop} {
			font-size: 24px;
		}
	}
`;

export const SharesContainer = styled.div`
	display: flex;
	padding-left: ${(props) => props.author && '47px'};
	padding-top: ${(props) => props.author && '19px'};

	@media (min-width: 600px) {
		padding: 0;
	}

	@media ${device.tablet} {
		flex-direction: ${(props) => (props.author ? null : 'column-reverse')};
		margin-top: 5px;
		margin-right: ${(props) => (props.author ? '10px' : 0)};
	}
`;

export const CountContainer = styled.div`
	display: flex;
	align-items: ${(props) => (props.author ? 'center' : 'flex-end')};

	@media ${device.tablet} {
		flex-direction: ${(props) => (props.author ? null : 'column')};
		justify-content: center;
		align-items: center;
	}
`;
export const Text = styled.h6`
	font-weight: 700;
	font-size: 10px;
	color: #9cacae;
	letter-spacing: 0.42px;
	line-height: 100%;
	margin-bottom: 2px;
	margin-left: 10px;
	margin-top: ${(props) => (props.author ? '6px' : '0px')};

	@media ${device.tablet} {
		margin-left: ${(props) => (props.author ? '10px' : '0px')};
		margin-top: 6px;
	}

	@media ${device.desktop} {
		margin-top: ${(props) => (props.author ? '11px' : '5px')};
		margin-left: ${(props) => (props.author ? '8px' : '5px')};
	}
`;
export const Number = styled.h5`
	font-family: AvenirNext;
	font-weight: 600;
	font-size: 18px;
	color: #19191a;
	letter-spacing: -0.5px;
	text-align: center;
	line-height: 100%;
	margin: 0;

	@media ${device.tablet} {
		font-size: ${(props) => props.author && '24px'};
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;

	@media ${device.tablet} {
		margin-top: ${(props) => (props.author ? null : '25px')};
		flex-direction: ${(props) => (props.author ? null : 'column')};
		width: ${(props) => (props.author ? null : '60px')};
		justify-content: flex-start;
	}
	@media ${device.tablet} {
		height: ${(props) => (props.author ? null : '120px')};
	}

	a {
		pointer-events: all;
		cursor: pointer;
		:hover {
			opacity: 0.7;
			transition: 70ms;
		}
	}
	div {
		outline: none;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	button {
		outline: none;
		background: none;
		border: none;
		display: block;
		padding: 0;
		margin-right: 12px;
		cursor: pointer;

		:hover {
			opacity: 0.7;
			transition: 70ms;
		}
		@media ${device.tablet} {
			margin-right: ${(props) => (props.author ? '20px' : 0)};
			margin-bottom: ${(props) => (props.author ? null : '22px')};
		}

		&.twitter-logo {
			width: 20px;
			g,
			path {
				fill: ${(props) => props.theme.colors.BrightTurquoise};
			}
		}
		&.facebook {
			g,
			path {
				fill: ${(props) => props.theme.colors.SanMarino};
			}
		}

		&.pinterest {
			pointer-events: none;
			g,
			path {
				fill: ${(props) => props.theme.colors.Cardinal};
			}
		}
	}

	svg {
		display: block;
		height: 20px;
		width: 20px;
	}
`;
