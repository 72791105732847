import React from "react"
import {
  Container,
  Title,
  TagsStyle,
  Content,
  Published,
  ArticleTag,
  ArticleTagsWrapper,
} from "./articleStyles"
import { Link } from "gatsby"
import moment from "moment"

export default function Article(props) {
  return (
    <Container>
      {props.categories && (
        <TagsStyle>
          {props.categories.slice(0, 4).map((category, index) => {
            return (
              <Link
                to={`/category/${category.slug}/`}
                key={index}
                dangerouslySetInnerHTML={{ __html: category.name }}
              />
            )
          })}
        </TagsStyle>
      )}
      <Title
        article={props.article}
        dangerouslySetInnerHTML={{ __html: props.title }}
      />
      <ArticleTagsWrapper>
        {props.article && (
          <Published>Published {moment(props.date).format("ll")} </Published>
        )}

        {props?.postLabel?.postLabel && (
          <ArticleTag>{props?.postLabel?.postLabel}</ArticleTag>
        )}
      </ArticleTagsWrapper>

      <Content
        article={props.article}
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </Container>
  )
}
