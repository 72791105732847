import React from 'react';

import { Container, ItemWrapper, Item } from './style.js';

import FacebookLogo from '../../images/fb-color.png';
import InstagramLogo from '../../images/ig-color.png';
// import TwitterLogo from '../../images/twitter-color.png';
import PinterestLogo from '../../images/pinterest-color.png';
import { ReactComponent as TwitterLogo } from '../../images/twitter-logo.svg';

const SocialLinks = (props) => {
	return (
		<Container>
			<h4>Follow Inspiration Grid</h4>
			<ItemWrapper>
				<Item target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/inspirationgrid/">
					<img src={InstagramLogo} alt="" />
					{/* <span>27k</span> */}
				</Item>

				<Item
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.pinterest.com.au/inspirationgrid/"
					className="pinterest"
				>
					<img src={PinterestLogo} alt="" />
					{/* <span>247k</span> */}
				</Item>

				<Item
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.facebook.com/TheInspirationGrid/"
					className="facebook"
				>
					<img className="fb" src={FacebookLogo} alt="" />
					{/* <span>24k</span> */}
				</Item>
				<Item
					target="_blank"
					rel="noopener noreferrer"
					href="https://twitter.com/inspirationgrid"
					className="twitter-logo"
				>
					{/* <img className="twitter" src={TwitterLogo} alt="" /> */}
					<TwitterLogo/>
					{/* <span>7k</span> */}
				</Item>
			</ItemWrapper>
		</Container>
	);
};

export default SocialLinks;
