import styled from "styled-components"
import { ReactComponent as Cross } from "../../../images/close-btn.svg"
import { device } from "../../../utils/breakpoints"
export const ModalContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  padding: 60px 30px 30px;

  @media (min-width: 1200px) {
    display: block;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 70.75%;
  height: auto;
  background: #19191a;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  @media ${device.big} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img.gif {
    display: none;
  }

  svg {
    width: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: ${props => (props.showImage ? "none" : "block")};

  }
`

export const ImageHighRes = styled.img`
  display: ${props => (props.showImage ? "block" : "none")};
  position: relative;
  z-index: 1;
  margin: 0;
  width: 100%;
`

export const ModalBody = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 25px 35px 0 rgba(0, 0, 0, 0.35);
`

export const CloseBtn = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 0;
  cursor: pointer;
  opacity: 1;
  transition: 100ms;

  :hover {
    opacity: 0.7;
  }
  svg {
    width: 16px;
    g {
      fill: #c6c9d5;
    }
  }
`

export const CrossIcon = styled(Cross)`
  height: 20px;
  width: 20px;

  g {
    fill: #fff;
  }
`

export const AdColumn = styled.div`
  border-radius: inherit;
  width: 29.25%;
  min-width: 380px;
  max-width: 470px;
  background: white;
  margin-left: auto;
  padding: 50px;
  padding-bottom: 20px;
  position: relative;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  > p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .advertisement {
    margin-bottom: -15px;
    /* background: none; */
    width: 340px;
    height: 310px;
    margin-left: -30px;
    @media ${device.large} {
      width: 120%;
      margin-left: -10%;
    }
    @media (min-width: 1440px) {
      background: #f4f4f4;
      width: 100%;
      margin-left: 0;
    }
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 28px;
  align-items: center;

  button {
    margin-right: 15px;
    outline: none;
    border: none;
    background: #e4316e;
    border-radius: 2px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
    }

    :first-of-type {
      svg {
        transform: scale(-1);
      }
    }

    transition: 70ms;
    :hover {
      background: #c8295f;
    }
  }

  > p {
    margin-left: auto;
    margin-bottom: 0;
    font-size: 20px;
    span {
      font-weight: 900;
      color: #19191a;
    }
  }
`

export const Title = styled.h3`
  font-size: 28px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d7e1e2;
  margin-bottom: 28px;
`
